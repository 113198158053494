.twitterPost {
  display: flex;
}

.twitterPostAvatar {
  margin-right: 12px;
  flex-basis: 48px;
}

.twitterPostBody {
  flex: 1;
  word-break: break-word;
  /* width: 82%; */
  /* margin-right: 16px; */
}

.twitterPostBody > img {
  border-radius: 20px;
}

.twitterPostHeaderDescription {
  font-size: 15px;
}

.likedOrRetweetComp {
  color: rgb(83, 100, 113);
  font: 14px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 74px;
}

/*Report feature Style*/
.report-container-tw{
  color: gray;
  font-size: 10px;
  border: none;
  cursor: pointer;
  text-align: center;
  right: 0;
  display: flex;
  align-items: end;
  padding: 15px 0 5px;
}
.report-text-tw{
  margin: 0;
  padding: 0 0 2px 5px;
}

.report-container-tw:hover{
  color: rgb(82, 82, 82);
}

.twitterPostHeaderThreeDots {
  margin-left: auto;
}

#simple-menu {
  margin-top: 20px;
}
.vertical-line{
  background-color: #d3d3d3ad;
  position: absolute;
  top: 72px;
  bottom: 0;
  width: 3px;
  height: 100%;
  left: 40px
}

/* max-width set on the postcard is 506 with 12 px padding on each side add front and take 12 off again; 506-24-48+12 */
.twitterPostTopText p{
  font-size: 15px;
  color: #0F1419;
  margin-top: 8px;
  /* max-width: 446px; */
}

.tweetBoxNoHover {
  color: #50b7f5;
  border-radius: 50% !important;
  padding: 9px !important;
  overflow: visible !important;
}

.twitterPostBottomMain {
  display: flex;
  height: 21px;
  margin-top: 12px;
}

.twitterPostBottomTop {
  flex-basis: 48px;
  margin-right: 8px;
}

.twitterPostHeaderMain {
  display: flex;
  align-items: center;
}

.twitterPostFooter {
  display: flex;
  align-items: center;
  /* max-width: 425px; */
  /* width: 75%; */
  width: 100%;
  justify-content: space-between;
}

.tweetBoxIcons {
  display: flex;
  align-items: center;
  border: none !important;
  /* min-width: 0 !important;
  border-radius: 50% !important; */
  transition: .2s;
  background: transparent;
}

.tweetBoxIcons:hover{
  background-color: transparent !important;
}

.tweetTopHeaderDots {
  color: var(--twitter-grey);
}

.tweetTopHeaderDots > span > svg {
  height: 15px;
  width: 15px;
}

.tweetTopHeaderDots:hover {
  color: rgb(29,155,240);
  background-color: rgba(29,155,240,0.1) !important;
}


/* .twitterRetweeted {
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
} */

.twitterRetweeted {
  display: flex;
  height: 16px;
  color: rgb(83,100,113);
  margin-bottom: 4px;
  margin-top: -4px;
}

.twitterRetweetedIcon {
  margin-right: 12px;
  flex-basis: 48px;
  display: flex;
  flex-direction: row-reverse;
}

.twUserPostRetweet {
  height: 16px;
  width: 16px;
  stroke: var(--twitter-grey);
  stroke-width: 2.5;
  fill: none;
}

.twUserPostLike {
  height: 16px;
  width: 16px;
  stroke: var(--twitter-grey);
  stroke-width: 1;
  fill: var(--twitter-grey);
}

.twitterRetweetedText {
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twUserPostLike {
  height: 16px;
  width: 16px;
  stroke: var(--twitter-grey);
  stroke-width: 1.5;
  fill: var(--twitter-grey);
}

.twitterBottomTextProps {
  color: var(--twitter-grey);
  font-size: 13px;
  /* padding-left: 12px; */
}

/* Twitter Like */
.twRed {
  color: var(--twitter-heart-color) !important;
}

.tweetBoxAllIcon{
  border-radius: 50% !important;
  padding: 5px;
  height: 1.25em;
  width: 1.25em;
  color: var(--twitter-grey);
}

.twLikeHover{
  background-color: var(--twitter-hover-like);
}

/* Twitter Reply */
.twBlue{
  color: var(--twitter-reply-color) !important;
}

.twReplyHover{
  background-color: var(--twitter-hover-reply);
}


/* Twitter Retweet */
.twGreen {
  color: var(--twitter-retweet-color) !important;
}

.twRetweetHover {
  background-color: var(--twitter-hover-retweet);
}

/* Twitter share */
.twButtonIconShare {
  stroke: var(--twitter-grey);
}

.twButtonIconShare:hover {
  background-color: var(--twitter-hover-reply) !important;
  stroke: var(--twitter-share-color);
}