.twitterRepliesPost {
  padding: 12px;
  margin: 12px -16px -12px -16px;
}

/* @media only screen and (max-width: 506px) {
  .twitterRepliesPost {
    margin-left: 0;
  }
} */

.hoverEffect:hover {
  /* background-color: rgba(0, 0, 0, 0.18); */
  background-color: rgba(0, 0, 0, 0.03);
  transition: 0.3s
}

/* here max-width takes into account the padding of 12px on left and right */
.twitterPostCard {
  justify-content: 'center';
  background-color: 'white';
  border-bottom: 1px solid rgb(0 0 0 / 10%) !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  /* max-height: 598px; */
  max-width: 506px;
}

@media only screen and (max-width: 506px) {
  .twitterPostCard {
    padding-left: 5px;
    padding-right: 5px;
  }
}

