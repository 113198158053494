.twitterSidebar {
  flex: 0.3;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.sidebarTweet {
  background-color: rgb(29,155,240);
  border: none;
  font-size: 17px;
  height: 52px;
  width: 225.89px;
  color: white;
  font-weight: 900;
  border-radius: 30px;
  margin-top: 20px;
}

.sidebarTweet:hover {
  background-color: rgb(26,155,240);
}
