.storyCreate {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 5px 7px -7px rgba(0,0,0,0.75);
  width: 100%;
}

.storyCreateTop {
  display: flex;
  /* border-bottom: 1px solid #eff2f5; */
  padding: 15px;
}

.storyCreateTop > form {
  flex: 1;
  display: flex;
}

.storyCreateTop > form > input {
  outline-width: 0;
  border: none;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 999px;
  background-color: #eff2f5;
}

.createStoryInputText {
  flex: 1;
}

.storyCreateBottom {
  display: flex;
  justify-content: space-evenly;
}

.storyCreateOption {
  padding: 20px;
  display: flex;
  align-items: center;
  color: grey;
  margin: 5px;
}

.storyCreateOption > h3 {
  font-size: small;
  margin-left: 10px;
  cursor: pointer;
}

.storyCreateOption:hover {
  background-color: #eff2f5;
  border-radius: 20px;
}


.postOption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.postOption > p {
  margin-left: 10px;
  font-size: 12px !important;
}

.postOption:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.postOptions {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  display: flex !important;
  justify-content: space-evenly !important;
  color: gray;
  cursor: pointer;
  align-items: stretch;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .onlyLargeScreen {
    display: none !important;
  }
}
