.rootContainer {
  /* background-color: #F2F3F5; */
  margin-bottom: 60px; /* facebook posts */
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: #efefef;
  text-align: center;
}

.nextButton {
  float: right;
}

.div > h1 {
  font-size: 16px;
}

.div > p {
  font-size: 16px;
}

.twitterCSS {
  box-shadow: none !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  border-radius: 5px 5px;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}