.postMessage {
  font-size: 12px !important;
}

.link-preview {
  /* display: table-caption; */             
  /* make text wrap under image  */
  font-size: small;
  text-decoration: none;
}

.fbSharedescriptions {
  background-color: #F2F3F5;
  padding: 5px;
}

.og-title {
  color: #828282;
  font-size: 12px !important;
  font-size: small;
  padding-bottom: 15px;
}

.og-description {
  text-overflow: ellipsis;
  color: black;
  font-size: 12px !important;
}

