.DraftEditor-root {
  font-size: 16px;
}

p {
  font-size: 16px;
}

.fakePostDetails {
  margin: 8px;
}

.showResponseCode {
  border: 1px solid lightgray;
  border-radius: 7px;
  text-align: center;
  color: #2472b2;
  width: 25%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}