.fbAuthorProfileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.twAuthorProfileImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}