.twitter-link-preview {
  text-decoration: none;
}

.twitterLinkThreeSideBorder {
  border-radius: 0 0 16px 16px;
  border: 1px solid rgb(207,217,222);
  padding: 12px;
  /* border-left: 1px solid rgb(207,217,222);
  border-right: 1px solid rgb(207,217,222); */
}

.twitterLinkCorrectMargin {
  margin-top: -5px;
}

.twitter-og-title {
  color: rgb(15,20,25);
  font-size: 15px;
}

.twitter-og-description {
  color: var(--twitter-grey);
  font-size: 15px;
  line-height: 17.3px;
  max-height: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twitterAllRoundBorder {
  border-radius: 16px;
  border: 1px solid rgb(207,217,222);
}

.twitterThreeSideBorder {
  border-radius: 16px 16px 0 0;
  border: 1px solid rgb(207,217,222);
  /* border-top: 1px solid rgb(207,217,222);
  border-left: 1px solid rgb(207,217,222);
  border-right: 1px solid rgb(207,217,222); */
}

.twitterLinkAllRoundBorder {
  border-radius: 16px;
  border: 1px solid rgb(207,217,222);
  padding: 12px;
}